<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <catalog-skeleton-layout v-if="loaderSkeleton" />
      <v-row v-else>
        <v-col cols="12">
          <!-- content cards -->
          <div v-if="aCatalogs.length > 0">
            <v-row>
              <v-col
                v-for="(item, index) in aCatalogs"
                :key="index"
                cols="12"
                xl="3"
                lg="4"
                md="4"
                sm="6"
                xs="12"
              >
                <div
                  class="card-container"
                  @click="redirect(item)"
                >
                  <div
                    :style="{ backgroundColor: item.sColorCode }"
                    class="content-color-card display-flex align-items-center"
                  >
                    <div class="ml-0 content-image">
                      <img
                        :src="item.oImages.sm"
                        alt=""
                      >
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="button-redirect mr-3"
                      icon
                      dark
                    >
                      <v-icon
                        size="18px"
                        color="#283C4D"
                      >
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="mt-5">
                    <p
                      v-if="restantHeightName"
                      :style="{ height: `${restantHeightName}px` }"
                      class="title-card mon-semibold"
                    >{{ item.sName }}</p>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <div
                  v-if="aCatalogs.length <= 6"
                  style="height: 150px"
                />
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <div class="empty-content display-flex align-items-center justify-content-center">
              <div>
                <div class="display-flex justify-content-center">
                  <img
                    width="170px"
                    src="@/assets/icons/empty.gif"
                    alt="proplat-image"
                  />
                </div>
                <p
                  class="text-empty mon-regular mb-9"
                  v-html="texts.catalog.textEmptyContent"
                />
              </div>
            </div>
          </div>
          <!-- <div
            v-if="aCatalogs.length <= 6 && aCatalogs.length >= 1"
            style="height: 150px"
          />
          <div
            v-if="aCatalogs.length >= 7"
            style="height: 10px"
          /> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "CatalogContentLayout",
  data() {
    return {
      //VARIABLES
      aCatalogs: [],
      loaderSkeleton: true,
      restantHeightName: 0,
      iCurrentPage: 1,
      lengthPage: 15,
    };
  },
  beforeMount() {
    this.getCatalogs();
  },
  created() {
    window.addEventListener("resize", this.resizeDivText);
    this.resizeDivText();
  },
  updated() {
    this.resizeDivText();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDivText);
  },
  methods: {
    redirect: function (item) {
      this.$router.push({
        name: "Subcatalog",
        params: { id: item.sCategoryId },
      });
    },
    resizeDivText: function () {
      var contentHeight = document.getElementsByClassName("title-card");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    getCatalogs: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {},
        params: {
          sCategoryTypeId: this.sFilter,
          sSearch: this.sSearch,
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 6,
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;

          this.aCatalogs = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
    sFilter: function () {
      return this.$store.state.sFilter;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getCatalogs();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getCatalogs();
    }, 500),
    sFilter: function () {
      this.iCurrentPage = 1;
      this.getCatalogs();
    },
    refresh: function () {
      this.getCatalogs();
    },
    iCurrentPage: function () {
      this.getCatalogs();
    },
  },
};
</script>

<style scoped>
.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}

.empty-content {
  width: 100%;
  height: 400px;
}

.content-image {
  width: 58px;
  height: 58px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.title-card {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
  text-transform: capitalize;

  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* background-color: red; */
}

.button-redirect {
  border: 2px solid #283c4d;
  width: 25px;
  height: 25px;
}

.content-color-card {
  /* background: #f1ed8c 0% 0% no-repeat padding-box; */
  border-radius: 19px;
  height: 50px;
  width: 100%;
  position: relative;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 15px;
}

.card-container:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>